import React, { useCallback, useEffect, useState } from 'react';
import {
  Badge,
  Button,
  Flex,
  Input,
  SimpleGrid,
  Text,
  Select,
  Box,
  useDisclosure,
  useToast,
  useMediaQuery,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import OrderCard from './order-card';
import { useDispatch, useSelector } from 'react-redux';
import {
  cancelOrderHandler,
  fetchOrders,
  setOrders,
} from '../../redux/orderSlice';
import CancelOrder from './cancel-order';

let copiedOrders = [];

const Orderlist = () => {
  const {
    isOpen: isOpenCancelOrder,
    onOpen: onOpenCancelOrder,
    onClose: onCloseCancelOrder,
  } = useDisclosure();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();
  const { userData } = useSelector((state) => state.user);
  const { guestInfo } = useSelector((state) => state.guest);
  const { orders, copiedOrders } = useSelector((state) => state.order);
  const [searchTerm, setSearchTerm] = useState('');
  const [orderId, setOrderId] = useState('');
  const [isSmallerThan767] = useMediaQuery('(max-width: 767px)');

  useEffect(() => {
    document.getElementsByClassName('chakra-container')[0].style.background =
      '#F0F1F7';
    document.body.classList.add('order-list');

    if (userData?.id) {
      dispatch(fetchOrders());
    }

    return () => {
      document.getElementsByClassName('chakra-container')[0].style.background =
        '#fff';
      document.body.classList.remove('order-list');
    };
  }, [dispatch, userData?.id]);

  const handleSearchInputChange = (e) => {
    if (e.target.value === '') {
      dispatch(fetchOrders());
    }
    setSearchTerm(e.target.value);
  };

  const searchOrders = useCallback(() => {
    const temp = [...orders];
    const _orders = temp.filter((order) =>
      order.product_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    dispatch(setOrders(_orders));
  }, [dispatch, orders, searchTerm]);

  const filterOrderByStatus = (status) => {
    const temp = [...copiedOrders];
    const _orders = temp.filter((order) => order.status === status);
    dispatch(setOrders(_orders));
  };

  return (
    <>
      {orders.length === 0 && (
        <Flex
          flexDir={'column'}
          gap={'10px'}
          justify={'center'}
          align={'center'}
          p='50px'
          minH='50vh'
        >
          <Text fontSize={'24px'}>Your order list is empty!</Text>
          <Text>Add items to it now.</Text>
          <Button variant='primary' onClick={() => navigate('/')}>
            Shop Now
          </Button>
        </Flex>
      )}
      {orders.length > 0 && (
        <Box pb={{ base: '80px', md: '30px' }} px={isSmallerThan767 ? '10px' : '0'}>
          <Flex py='20px' justify={'space-between'} align={'center'}>
            <Text fontSize={'1.125rem'} fontWeight={600}>
              Order List
            </Text>
          </Flex>
          <Flex
            flexDir={{ base: 'column', md: 'row' }}
            className='box'
            justify={'space-between'}
            align={'center'}
            height={isSmallerThan767 ? 'auto' : '60px'}
            padding={isSmallerThan767 ? '10px 15px' : '0 15px'}
          >
            <Text fontSize={'.9375rem'}>
              Total <Badge mx='5px'>{orders.length}</Badge> products are in the
              order list
            </Text>
            <Flex gap={'10px'}>
              <Select
                onChange={(e) => {
                  if (!e.target.value) {
                    return dispatch(setOrders(copiedOrders));
                  }
                  filterOrderByStatus(e.target.value);
                }}
                placeholder='Sort By Status'
                bg='#F9F9F9'
              >
                <option value='created'>Created</option>
                <option value='submitted'>Submitted</option>
                <option value='shipped'>Shipped</option>
                <option value='cancelled'>Cancelled</option>
                <option value='delivered'>Delivered</option>
              </Select>
              <Input
                placeholder='Search'
                value={searchTerm}
                onChange={handleSearchInputChange}
                focusBorderColor={'primary.100'}
              />
              <Button variant={'primary'} onClick={searchOrders}>
                Search
              </Button>
            </Flex>
          </Flex>
          <SimpleGrid
            columns={{ base: 1, md: 2, lg: 4 }}
            gap='20px'
            alignItems='center'
            justifyItems='center'
          >
            {orders.map((order) => (
              <OrderCard
                key={order.id}
                order={order}
                onCancel={(id) => {
                  setOrderId(id);
                  onOpenCancelOrder();
                }}
              />
            ))}
          </SimpleGrid>
          <CancelOrder
            isOpen={isOpenCancelOrder}
            onClose={onCloseCancelOrder}
            confirmCancel={() => {
              dispatch(cancelOrderHandler(orderId)).then((res) => {
                if (res.status === 200) {
                  onCloseCancelOrder();
                  toast({
                    title: 'Order Cancelled',
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                  });
                } else {
                  toast({
                    title: 'Something went wrong',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                  });
                }
              });
            }}
          />
        </Box>
      )}
    </>
  );
};

export default Orderlist;
