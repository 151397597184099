import { Box, Divider, Flex, Text } from '@chakra-ui/react';
import React from 'react';
import styles from './recommendations.module.scss';
import { useNavigate } from 'react-router-dom';
import RecommendationListing from '../../recommendation-listing';

const Recommendations = ({ title, items, onCartOpen }) => {
  const navigate = useNavigate();

  return (
    <Box
      className={styles.recommendations}
      bg='#FFF'
      mt='40px'
      // padding={'20px 29px'}
    >
      <Flex justify={'space-between'} align={'center'}>
        <Text color={'#000'} fontSize={'16px'} fontWeight={500} mb='15px'>
          {title}
        </Text>
        {/* <Flex align={'center'} gap={'5px'} cursor={'pointer'}>
          <Text
            lineHeight={'18px'}
            fontWeight={700}
            fontSize={'16px'}
            color={'#222'}
            onClick={() => navigate('/product/listing')}
          >
            View All
          </Text>
          <ArrowRight />
        </Flex> */}
      </Flex>
      <Divider mb='35px' mt='-20px' />

      <RecommendationListing fromHome onCartOpen={onCartOpen} />
    </Box>
  );
};

export default Recommendations;
