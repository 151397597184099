import moment from 'moment';

export const percentage = (partialValue, totalValue) => {
  return (100 * parseFloat(partialValue)) / parseFloat(totalValue).toFixed(2);
};

// Function to calculate the discount percentage
export function calculateDiscountPercentage(
  originalPrice = 0,
  discountedPrice = 0
) {
  if (!originalPrice || originalPrice <= 0 || !discountedPrice) {
    return 0;
  }

  // Remove all commas from prices and parse to floats
  const parsedOriginalPrice = parseFloat(
    originalPrice.toString().replace(/,/g, '')
  );
  const parsedDiscountedPrice = parseFloat(
    discountedPrice.toString().replace(/,/g, '')
  );

  // Ensure discountedPrice is not greater than originalPrice
  if (parsedDiscountedPrice >= parsedOriginalPrice) {
    return 0;
  }

  // Calculate the discount and discount percentage
  const discount = parsedOriginalPrice - parsedDiscountedPrice;
  const discountPercentage = (discount / parsedOriginalPrice) * 100;

  // Return the rounded discount percentage
  return Math.round(discountPercentage);
}

// export function calculateDiscountPrice(originalPrice = 0, discountedPrice = 0) {
//   if (!originalPrice && !discountedPrice) {
//     return;
//   }
//   // Ensure originalPrice is greater than 0 to avoid division by zero
//   //   if (originalPrice <= 0) {
//   //     throw new Error('Original price must be greater than zero');
//   //   }

//   // Calculate the discount
//   const discount =
//     parseFloat(
//       originalPrice.includes(',')
//         ? originalPrice.includes(',', '')
//         : originalPrice
//     ) -
//     parseFloat(
//       discountedPrice.includes(',')
//         ? discountedPrice.replace(',', '')
//         : discountedPrice
//     );

//   // Return the result
//   return discount.toFixed(2) || 0;
// }

export function calculateDiscountPrice(originalPrice = 0, discountedPrice = 0) {
  const formattedOriginalPrice = parseFloat(originalPrice);
  const formattedDiscountedPrice = parseFloat(discountedPrice);

  const discount = formattedOriginalPrice - formattedDiscountedPrice;

  return discount.toFixed(2);
}

export const formatePrice = (price) => {
  return parseFloat(price?.replace(',', ''));
};

export const isPastDate = (date) => {
  // moment is before
  console.log(moment().isBefore(date), 'moment().isBefore(date)');

  return moment().isBefore(date);
};

export const isBeforeDate = (date) => {
  // moment is before
  const currentDate = moment().format('YYYY-MM-DD HH:mm:ss');
  const selectedDate = moment(date).format('YYYY-MM-DD HH:mm:ss');

  return moment(currentDate).isAfter(selectedDate);
};

export function hasObjectChanged(original, updated) {
  for (const key in original) {
    if (original[key] !== updated[key]) {
      return true; // A property has changed
    }
  }
  return false;
}
