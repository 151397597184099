import React, { useCallback, useEffect, useRef, useState } from 'react';
import ArrowUpCard from '../../../assets/img/icons/Chevron.png';
import boxIcon from '../../../assets/img/icons/boxIcon.svg';

import {
  Box,
  Button,
  Checkbox,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  Flex,
  useMediaQuery,
  useToast,
} from '@chakra-ui/react';
import {
  RiArrowDownSLine,
  RiArrowUpSLine,
  RiCloseLargeFill,
} from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  applyCouponHandler,
  fetchCartList,
  removeCoupon,
  setCoupon,
  setIsCouponAppliedState,
  setUpdatedCartDetails,
} from '../../../redux/cartSlice';
import { setVoucherData } from '../../../redux/paymentSlice';
import { usehashParam } from '../../../hooks/useHashParam';
import { getDataProductSummary } from '../../../redux/productSlice';
import { formatePrice } from '../../../utils/functions';

const SummaryMobile = ({
  data,
  isOpenSummary,
  setIsOpenSummary,

  handleSelectAll,
  selectedItems,
  cartItems,
  fromHeader,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();
  const { userData } = useSelector((state) => state.user);
  const { guestInfo } = useSelector((state) => state.guest);
  const { cartDetails, updatedCartDetails, coupon } = useSelector(
    (state) => state.cart
  );
  const { isCouponAppliedState } = useSelector((state) => state?.cart);
  const [appliedCoupon, setAppliedCoupon] = useState('');
  const buttonDisabledForCoupon = updatedCartDetails?.is_button_disable;
  const [isCouponApplied, setIsCouponApplied] = useState(false);
  const firstField = React.useRef();
  const [isSmallerThan767] = useMediaQuery('(max-width: 767px)');
  const [priceDetails, setPriceDetails] = useState(null);

  const handleApplyCoupon = async () => {
    try {
      const payload = {
        user_id: String(userData?.id ?? guestInfo?.id),
        sub_total:
          priceDetails?.sub_total_amount ?? cartDetails?.sub_total_amount ?? 0,
        discount: priceDetails?.discount ?? cartDetails?.discount ?? 0,
        shipping:
          priceDetails?.shipment_amount ?? cartDetails?.shipment_amount ?? 0,
        total: priceDetails?.total_amount ?? cartDetails?.total_amount ?? 0,
        coupon_code: appliedCoupon,
      };

      const response = await dispatch(applyCouponHandler(payload));
      const responseData = response.data.data;
      if (response?.status === 200 || responseData?.statusCode === true) {
        dispatch(setCoupon(appliedCoupon));
        dispatch(setIsCouponAppliedState(true));
        dispatch(setUpdatedCartDetails(responseData));
        dispatch(setVoucherData({ ...responseData, code: appliedCoupon }));
        toast({
          title: 'Success',
          description: response.data?.message || 'Your coupon was applied!',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        setIsCouponApplied(true);
      } else {
        toast({
          title: 'Failed',
          description:
            response?.data?.message ||
            'An error occurred while applying the coupon',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: 'Failed to apply coupon',
        description:
          error.response?.data?.message || 'An unexpected error occurred.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleRemoveCoupon = async () => {
    try {
      await dispatch(fetchCartList(userData?.id || guestInfo?.id));
      setAppliedCoupon('');
      setIsCouponApplied(false);
      dispatch(removeCoupon());
      dispatch(setIsCouponAppliedState(false));
      dispatch(setUpdatedCartDetails(null));
      dispatch(setVoucherData({}));
      toast({
        title: 'Coupon removed successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Failed to remove coupon',
        description:
          error.message || 'An error occurred while removing the coupon',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (fromHeader && !isSmallerThan767)
      document.body.classList.add('cart-mobile-open');

    return () => {
      document.body.classList.remove('cart-mobile-open');
    };
  }, [fromHeader, isSmallerThan767]);

  const isDiscountMoreThanTotal = () => {
    if (
      formatePrice(updatedCartDetails?.discount) >
      formatePrice(priceDetails?.total_amount)
    ) {
      return true;
    }
    return false;
  };

  const getProductPricesByIds = useCallback(
    async (is_Id) => {
      const object = {
        user_id: is_Id,
        cart_id: selectedItems.map((item) => item.id),
      };
      dispatch(getDataProductSummary(object)).then((res) => {
        if (res.status === 200) {
          setPriceDetails(res.data);
        }
      });
    },
    [dispatch, selectedItems]
  );

  useEffect(() => {
    if (selectedItems?.length > 0 && (guestInfo?.id || userData?.id)) {
      const userId = userData?.id || guestInfo?.id;
      getProductPricesByIds(userId);
    } else {
      setPriceDetails([]);
    }
  }, [selectedItems, guestInfo?.id, userData?.id, getProductPricesByIds]);


  return (
    <>
      <Box
        className='llt_cart_checkout_bx'
        bottom={fromHeader ? '0' : '59px'}
        style={{ padding: '15px 20px' }}
      >
        <Box className='llt_cart_check_all'>
          <Checkbox
            isChecked={selectedItems.length === cartItems.length}
            onChange={handleSelectAll}
            fontSize='20px'
            fontWeight={700}
            color='#000'
          >
            All
          </Checkbox>
        </Box>
        <div className='llt_cart_total_price'>
          {fromHeader ? (
            <h6>
              {' '}
              {
                updatedCartDetails?.total_amount
                  ? isDiscountMoreThanTotal()
                    ? '0.00'
                    : updatedCartDetails?.total_amount
                  : priceDetails?.total_amount || '0.00'
              }
            </h6>
          ) : (
            <h6
              className=''
              onClick={(e) => {
                if (!isOpenSummary) {
                  setAppliedCoupon('');
                }

                setIsOpenSummary(!isOpenSummary);
              }}
            >
              {
                updatedCartDetails?.total_amount
                  ? isDiscountMoreThanTotal()
                    ? '0.00'
                    : updatedCartDetails?.total_amount
                  : priceDetails?.total_amount || '0.00'
              }
              <Box>
                {isOpenSummary ? (
                  <RiArrowDownSLine color='#f41106' size={'30px'} />
                ) : (
                  <RiArrowUpSLine color='#f41106' size={'30px'} />
                )}
              </Box>
            </h6>
          )}

          {!fromHeader && (
            <button
              className='checkout-btn'
              onClick={() => {
                if (isDiscountMoreThanTotal()) {
                  navigate(`/create/order?discount=${usehashParam('all')}`);
                } else {
                  navigate('/create/order');
                }
              }}
              disabled={selectedItems.length === 0}
              style={{
                cursor: selectedItems.length === 0 ? 'not-allowed' : 'pointer',
                opacity: selectedItems.length === 0 ? 0.6 : 1,
              }}
            >
              Checkout ({selectedItems?.length})
            </button>
          )}
        </div>
        {fromHeader && (
          <Flex mt='10px' justify={'flex-end'} width={'100%'}>
            <Button
              w='90px'
              h='30px'
              varaint='primary'
              onClick={(e) => navigate('/product/cart')}
              size={'sm'}
              padding={'10px 15px'}
              height={'30px'}
            >
              View Cart
            </Button>
          </Flex>
        )}
      </Box>

      {isOpenSummary && (
        <Drawer
          placement={'bottom'}
          onClose={(e) => {
            setIsOpenSummary(false);
            setAppliedCoupon('');
          }}
          isOpen={(e) => setIsOpenSummary(true)}
          initialFocusRef={firstField}
        >
          <DrawerContent className='teetete'>
            <DrawerHeader
              className='llt_card_price_priveiw'
              alignItems='center'
            >
              Summary
              <span
                onClick={(e) => {
                  setIsOpenSummary(false);
                  setAppliedCoupon('');
                }}
              >
                {' '}
                <RiCloseLargeFill />
              </span>
            </DrawerHeader>
            <DrawerBody>
              <div className='llt_cart_summary_bx'>
                <div className='llt_cart_bx_sum' ref={firstField}>
                  <span>Sub Total</span>
                  <span>
                    {updatedCartDetails?.sub_total_amount
                      ? updatedCartDetails?.sub_total_amount
                      : priceDetails?.sub_total_amount
                        ? priceDetails?.sub_total_amount
                        : '0.00'}
                  </span>
                </div>
                <div className='llt_cart_bx_sum'>
                  <span>Discount</span>
                  <span>
                    {updatedCartDetails?.discount
                      ? updatedCartDetails?.discount
                      : priceDetails?.discount
                        ? priceDetails?.discount
                        : '0.00'}
                  </span>
                </div>
                <div className='llt_cart_bx_sum'>
                  <span>Delivery Charges</span>
                  <span>
                    {updatedCartDetails?.shipment_amount
                      ? updatedCartDetails?.shipment_amount
                      : priceDetails?.shipment_amount
                        ? priceDetails?.shipment_amount
                        : '0.00'}
                  </span>
                </div>
                <div className='llt_cart_bx_sum'>
                  <span> Total</span>
                  <span>
                    {
                      updatedCartDetails?.total_amount
                        ? isDiscountMoreThanTotal()
                          ? '0.00'
                          : updatedCartDetails?.total_amount
                        : priceDetails?.total_amount || '0.00'
                    }
                  </span>
                </div>
                <div className='llt_cart_fast_delivery'>
                  <img src={boxIcon} alt='Shipment Icon' />
                  <h6>Fast Delivery</h6>
                </div>
                <div>
                  <div className='llt_cart_fast_delivery'>
                    <img src={boxIcon} alt='Shipment Icon' />
                    <h6>Security & Privacy</h6>
                  </div>
                  <div className='llt_cart_privacy_rules'>
                    <p>
                      Safe payments: We do not share your personal details with
                      any third parties without your consent.
                    </p>
                    <p>
                      Secure personal details: We protect your privacy and keep
                      your personal details safe and secure.
                    </p>
                  </div>
                </div>
                <div className='llt_cart_fast_delivery'>
                  <img src={boxIcon} alt='Shipment Icon' />
                  <h6>Buyer Protection</h6>
                </div>
                <div className='llt_cart_input_coupan'>
                  <input
                    autofocus={false}
                    placeholder='Coupon Code'
                    flex='1'
                    borderRightRadius='0'
                    width='100%'
                    border='#e9edf6 1px solid'
                    value={coupon}
                    onChange={(e) => setAppliedCoupon(e.target.value)}
                    disabled={isCouponAppliedState}
                  />
                  <button
                    variant='secondary'
                    color='black'
                    maxW='150px'
                    borderLeftRadius='0'
                    style={{
                      backgroundColor: isCouponAppliedState ? '#E6D9F4' : 'initial',
                      border: isCouponAppliedState
                        ? '1px solid #8A2BE2'
                        : '1px solid black',
                    }}
                    onClick={
                      isCouponAppliedState ? handleRemoveCoupon : handleApplyCoupon
                    }
                  >
                    {isCouponAppliedState ? 'Remove' : 'Apply'}
                  </button>
                </div>
              </div>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      )}
    </>
  );
};

export default SummaryMobile;
