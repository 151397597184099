import React, { useEffect, useState } from 'react';
import {
  Badge,
  Button,
  Flex,
  Input,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchWishList, setWishList } from '../../redux/wishlistSlice';
import { useNavigate } from 'react-router-dom';
import Product from './product';

let count = 0;

const Wishlist = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.user);
  const { guestInfo } = useSelector((state) => state.guest);
  const { wishList } = useSelector((state) => state.wishlist);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredWishList, setFilteredWishList] = useState(wishList);

  // useEffect(() => {
  //   if (count === 0) {
  //     dispatch(fetchWishList(userData?.id || guestInfo.id));
  //     count++
  //   }
  // }, [dispatch, guestInfo.id, userData?.id, wishList]);

  useEffect(() => {
    setFilteredWishList(
      wishList?.filter((item) => {
        const itemName = item.name || '';
        return itemName.toLowerCase().includes(searchTerm.toLowerCase());
      })
    );
  }, [searchTerm, wishList]);

  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <div style={{ marginBottom: '20px' }}>
      {wishList?.length === 0 && (
        <Flex
          flexDir={'column'}
          gap={'10px'}
          justify={'center'}
          align={'center'}
          p='50px'
          minH='50vh'
        >
          <Text fontSize={'24px'}>Your wishlist is empty!</Text>
          <Text>Add items to it now.</Text>
          <Button variant='primary' onClick={() => navigate('/')}>
            Shop Now
          </Button>
        </Flex>
      )}
      {wishList?.length > 0 && (
        <>
          <Flex py='20px' justify={'space-between'} align={'center'}>
            <Text fontSize={'1.125rem'} fontWeight={600}>
              Wishlist
            </Text>
          </Flex>
          <Flex
            p='1rem'
            flexDir={'row'}
            className='box'
            justify={'space-between'}
            align={'center'}
            borderBottom='gray 1px solid'
          >
            <Text fontSize={'.9375rem'}>
              Total{' '}
              <Badge bg='red' color='white' mx='5px'>
                {wishList?.length}
              </Badge>{' '}
              products are wishlisted
            </Text>
            <Flex gap={'10px'}>
              <Input
                placeholder='Search'
                value={searchTerm}
                onChange={handleSearchInputChange}
                focusBorderColor={'primary.100'}
              />
              <Button variant={'primary'}>Search</Button>
            </Flex>
          </Flex>
          {/* <hr/> */}
          {filteredWishList?.length > 0 ? (
            <SimpleGrid spacing='20px' columns={[1, 2, 3, 4, 5]}>
              {filteredWishList?.map((item, index) => (
                <Product
                  key={item.id}
                  data={item}
                  onMouseEnter={() => {
                    const _temp = [...filteredWishList];
                    const map = _temp.map((item, itemIndex) => {
                      if (index === itemIndex) {
                        return { ...item, showIcons: true };
                      }
                      return { ...item, showIcons: false };
                    });
                    dispatch(setWishList(map));
                  }}
                  onMouseLeave={() => {
                    const _temp = [...filteredWishList];
                    const map = _temp.map((item, itemIndex) => {
                      if (index === itemIndex) {
                        return { ...item, showIcons: false };
                      }
                      return { ...item, showIcons: false };
                    });
                    dispatch(setWishList(map));
                  }}
                />
              ))}
            </SimpleGrid>
          ) : (
            <Flex
              flexDir={'column'}
              gap={'10px'}
              justify={'center'}
              align={'center'}
              p='50px'>
              <Text fontSize={'24px'}>No product with this name found!</Text>
            </Flex>
          )}
        </>
      )}
    </div>
  );
};

export default Wishlist;
