import React, { useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Input,
  SimpleGrid,
  Text,
  VStack,
  Divider,
  useToast,
  useMediaQuery,
  Select,
} from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import { MdAccountCircle } from 'react-icons/md';
import FormInput from '../../../components/form-input'; // Adjust the path if needed
import { useDispatch, useSelector } from 'react-redux';
import { ezipayHandler } from '../../../redux/paymentSlice';
import isEmpty from 'is-empty';
import { createOrderHandler, setOrderDetails } from '../../../redux/orderSlice';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import FormInputGroup from '../../../components/form-input-group';
import { useQuery } from '../../../hooks/useQuery';

const Payment = ({ setTabIndex }) => {
  const toast = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { orderDetails, checkoutData } = useSelector((state) => state.order);
  const { countries, currency } = useSelector((state) => state.home);
  const { voucherData } = useSelector((state) => state.payment);

  const [isSmallerThan767] = useMediaQuery('(max-width: 767px)');
  const [isLoading, setIsLoading] = useState(false);
  const query = useQuery();

  const orderHandler = (transactionId, phone_code) => {
    const {
      shipping: {
        shipping_name,
        shipping_email,
        shipping_phone,
        shipping_address,
        shipping_pincode,
        shipping_city,
        // shipping_state,
        shipping_country,
        billing_name,
        billing_email,
        billing_phone,
        billing_address,
        billing_pincode,
        billing_city,
        // billing_state,
        billing_country,
      },
    } = orderDetails;
    const payload = {
      subTotal: checkoutData?.sub_total_amount,
      total: checkoutData?.total_amount,
      shipping: checkoutData?.shipment_amount,
      payment_method: 'EziPay',
      payment_id: transactionId,
      shipping_name,
      shipping_email,
      shipping_phone,
      shipping_address,
      shipping_pincode,
      shipping_city,
      // shipping_state,
      shipping_country,
      billing_name: billing_name || shipping_name,
      billing_email: billing_email || shipping_email,
      billing_phone: billing_phone || shipping_phone,
      billing_address: billing_address || shipping_address,
      billing_pincode: billing_pincode || shipping_pincode,
      billing_city: billing_city || shipping_city,
      // billing_state: billing_state || shipping_state,
      billing_country: billing_country || shipping_country,
      phone_code,
    };
    if (!isEmpty(voucherData)) {
      payload.is_voucher = 1;
      payload.voucher_code = voucherData.code;
      payload.voucher_amount = voucherData.discount;
    }
    dispatch(createOrderHandler(payload))
      .then((res) => {
        if (res.status === 200) {
          localStorage.removeItem('cartDetails');
          dispatch(setOrderDetails({ paymentDone: true }));
          setTabIndex(2);
          // toast({
          //   title: 'Order placed successfully!',
          //   status: 'success',
          // });
        }
        setIsLoading(false);
      })
      .catch((error) => {
        toast({
          title: error.message || 'Something went wrong!',
          status: 'error',
        });
        setIsLoading(false);
      });
  };

  return (
    <Box
      className='checkout-border'
      backgroundColor='white'
      width={isSmallerThan767 ? '100%' : '70%'}
    >
      <Formik
        enableReinitialize={true}
        initialValues={{
          deliveryAddress: !isEmpty(orderDetails?.shipping)
            ? `${orderDetails?.shipping?.shipping_name}, ${orderDetails?.shipping?.shipping_address}, ${orderDetails?.shipping?.shipping_city},${orderDetails?.shipping?.shipping_country}`
            : '',
          isChangeAddress: false,
          paymentType: 'ezipay',
          cardNumber: '',
          nameOnCard: '',
          expirationDate: '',
          cvv: '',
          otp: '',
          savedCard: '7646', // Default selected card
          mobile: orderDetails?.shipping_phone || '',
          coutryCode: countries?.find((j) => j.currency === currency)
            ?.country_code,
        }}
        validationSchema={Yup.object({
          mobile: Yup.string()
            .matches(/^[1-9]\d{0,8}$/, 'Invalid number')
            .required('Phone number is required')
            .min(9, 'Invalid number'),
        })}
        onSubmit={(values) => {
          setIsLoading(true);
          const payload = {
            amount: parseFloat(checkoutData?.total_amount.replace(',', '')),
            mobile: `${values.coutryCode}${values.mobile}`,
          };
          if (query.get('order_id')) {
            payload.order_id = query.get('order_id');
          }
          dispatch(ezipayHandler(payload)).then((res) => {
            if (res.status === 200) {
              if (query.get('order_id')) {
                localStorage.removeItem('cartDetails');
                dispatch(setOrderDetails({ paymentDone: true }));
                setTabIndex(2);
                return;
              }
              orderHandler(
                res?.data?.data?.transactionId,
                `+${values.coutryCode}`
              );
            } else {
              toast({
                title:
                  res?.response?.data?.message?.message ||
                  res?.response?.data?.message ||
                  'Something went wrong!',
                status: 'error',
              });
              setIsLoading(false);
            }
          });
        }}
      >
        {({ isValid, values, setFieldValue }) => (
          <Form>
            <VStack p={4} align='start'>
              <Text fontSize='1.5rem' fontWeight='bold' color='gray.200'>
                02
              </Text>
              <Text fontSize='lg' fontWeight='bold'>
                Delivery Address
              </Text>

              <Box display='flex' alignItems='center' w='100%'>
                <Input
                  label='Delivery Address'
                  name='deliveryAddress'
                  placeholder=''
                  readOnly={!values.isChangeAddress}
                  borderRadius='10px 0 0 10px'
                  value={values.deliveryAddress}
                />
                <Button
                  h='40px'
                  borderRadius='0 10px 10px 0'
                  onClick={() => setTabIndex(0)}
                >
                  {values.isChangeAddress ? 'Save' : 'Change'}
                </Button>
              </Box>

              <Box
                width='100%'
                p={4}
                border='1px'
                borderColor='gray.200'
                borderRadius='md'
              >
                <Flex flexDir='row'>
                  <Box
                    width='5px'
                    height='25px'
                    bgGradient='linear(to-b, #B5B8F0, #A5C7F1)'
                    mr={2}
                  />
                  <Text fontSize='md' fontWeight='bold' mb={2}>
                    Payment Methods
                  </Text>
                </Flex>
                <Divider borderColor='gray.200' mb={4} />

                <Flex marginX='auto' width='97%'>
                  <SimpleGrid
                    columns={{ base: 1, md: 2 }}
                    width='100%'
                    mb={4}
                    justifyItems='center'
                    gap='10px'
                  >
                    <Button
                      variant={
                        values.paymentType === 'ezipay'
                          ? 'primary'
                          : 'secondary'
                      }
                      bg='#ffefee'
                      color={'rgb(244, 17, 6)'}
                      width='100%'
                      onClick={() => {
                        setFieldValue('paymentType', 'ezipay');
                      }}
                    >
                      Ezipay
                    </Button>

                    <Box textAlign='center' width='100%'>
                      <Button
                        variant={
                          values.paymentType === 'paypal'
                            ? 'primary'
                            : 'secondary'
                        }
                        width='100%'
                        onClick={() => {
                          setFieldValue('paymentType', 'paypal');
                        }}
                        disabled
                      >
                        PayPal
                      </Button>
                      <Text color='green.500' fontSize='sm' mt='8px'>
                        Coming Soon
                      </Text>
                    </Box>
                    {/* <Button
                        width='100%'
                        variant={
                          values.paymentType === 'card'
                            ? 'primary'
                            : 'secondary'
                        }
                        onClick={() => {
                          setFieldValue('paymentType', 'card');
                        }}
                      >
                        Credit/Debit Card
                      </Button> */}
                  </SimpleGrid>
                </Flex>
                <Flex
                  width={{ base: '100%', md: '100%' }}
                  flexDir={'column'}
                  gap={'15px'}
                  align={'center'}
                >
                  <Box w={isSmallerThan767 ? '100%' : '500px'}>
                    <Text
                      textStyle='body-rg'
                      fontSize='0.8rem'
                      fontWeight={600}
                      lineHeight='18px'
                      mb='10px'
                    >
                      Channel
                    </Text>
                    <Select>
                      <option>Yas</option>
                      <option>Airtel</option>
                      <option>M-PESA (Vodacom)</option>
                    </Select>
                  </Box>

                  <Box>
                    <Text
                      textStyle='body-rg'
                      fontSize='0.8rem'
                      fontWeight={600}
                      lineHeight='18px'
                    >
                      Mobile
                    </Text>
                    <Flex gap={'5px'} align={'center'}>
                      <FormInputGroup
                        label={''}
                        name='mobile'
                        placeholder='Enter mobile number'
                        flexWidth={isSmallerThan767 ? '100%' : '80%'}
                        groupValue={`+${values.coutryCode}`}
                      />
                    </Flex>
                  </Box>

                  <Flex mt='15px' justify={'center'} align={'center'}>
                    <Button
                      isLoading={isLoading}
                      loadingText='Please wait...'
                      type='"submit'
                      variant={'primary'}
                      isDisabled={!isValid || isEmpty(values.mobile)}
                    >
                      Pay Now
                    </Button>
                  </Flex>
                </Flex>
              </Box>

              <hr />
              <Flex
                gap={2}
                mt={10}
                borderTop='#EDEDED 1px solid'
                pt={5}
                width='100%'
                justifyContent='space-between'
                flexDir={{ base: 'column', md: 'row' }}
              >
                <Button
                  leftIcon={<MdAccountCircle />}
                  color='#E6533C'
                  backgroundColor='#FCEDEB'
                  _hover={{ bg: '#E6533C', color: 'white' }}
                  onClick={() => setTabIndex(0)}
                >
                  Back To Shipping Address
                </Button>
                {/* <Button
                    leftIcon={<MdCreditCard />}
                    color='#26BF94'
                    backgroundColor='#E9F8F4'
                    _hover={{ bg: '#26BF94', color: 'white' }}
                    type='submit'
                  >
                    Continue Payment
                  </Button> */}
              </Flex>
            </VStack>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default Payment;
