import axios from 'axios';

export const signup = async (payload) => {
  try {
    return await axios.post('/register', payload);
  } catch (error) {
    return error;
  }
};
export const login = async (payload) => {
  try {
    return await axios.post('/login', payload);
  } catch (error) {
    return error;
  }
};
export const logout = async (userId) => {
  try {
    return await axios.get(`/logout?user_id=${userId}`);
  } catch (error) {
    return error;
  }
};

export const sendingOtp = async (payload) => {
  try {
    return await axios.post('/forgot-password', payload);
  } catch (error) {
    return error;
  }
};
export const verifyingOtp = async (payload) => {
  try {
    return await axios.post('/verify-otp', payload);
  } catch (error) {
    return error;
  }
};
export const resetingPassword = async (payload) => {
  try {
    return await axios.post('/reset-password', payload);
  } catch (error) {
    return error;
  }
};

export const accountVerification = async (token) => {
  try {
    return await axios.get(`verify-account?token=${token}`);
  } catch (error) {
    return error;
  }
};
export const loginWithGoogle = async (paylaod) => {
  try {
    return await axios.post(`/auth/google`, paylaod);
  } catch (error) {
    return error;
  }
};
