import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CiBellOn } from "react-icons/ci";
import { Box, Text, Menu, MenuButton, MenuList, MenuItem, Button, useMediaQuery } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import NotificationCard from "../../pages/notifications/notification-card";
import { readNotificationsHandler } from "../../redux/userSlice";
import { useEffect } from "react";
import { setOnLoginOpen, setRedirectionPath } from "../../redux/homeSlice";

let count = 0;
const NotificationIcon = () => {
  const dispatch = useDispatch();
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();
  const { userData } = useSelector((state) => state.user);
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const notificationCount = useSelector((state) => state.home?.homeData?.unread_notification_count)
  const { notificationsList } = useSelector((state) => state?.user);

  const handleIconClick = (e) => {
    if (!userData?.id) {
      dispatch(setOnLoginOpen(true));
      dispatch(setRedirectionPath("/notifications"));
    } else if (isMobile) {
      navigate("/notifications");
    }
  };

  // useEffect(() => {
  //   if (userData?.id && count === 0) {
  //     dispatch(readNotificationsHandler(userData?.id));
  //     count++
  //   }
  // }, []);

  return (
    <Menu isLazy>
      <MenuButton
        as={Box}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        cursor="pointer"
        onClick={(e) => {
          handleIconClick();
          e.stopPropagation();
        }}
      >
        <Box position="relative" display="inline-block" >
          <CiBellOn size={24} color={isHovered ? 'red' : '#808080'} />
          {notificationCount > 0 && (
            <Box
              position="absolute"
              top="-5px"
              right="-5px"
              bg="red.500"
              borderRadius="full"
              width="18px"
              height="18px"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Text fontSize="xs" color="white" fontWeight="bold">
                {notificationCount}
              </Text>
            </Box>
          )}
        </Box>
      </MenuButton>
      {userData?.id && !isMobile && (
        <MenuList
          maxHeight="600px"
          maxWidth="600px"
          overflowY="auto"
          p={0}
        >
          <Box>
            {notificationsList?.length > 0 ? (
              notificationsList?.slice(0, 5).map((notification, index) => (
                <MenuItem key={notification.id}>
                  <NotificationCard notification={notification} />
                </MenuItem>
              ))
            ) : (
              <MenuItem>No new notifications</MenuItem>
            )}
          </Box>
          <Box
            m={-2}
            bg="white"
            position="sticky"
            bottom="0"
            zIndex="10"
            p={2}
            boxShadow="0 -1px 5px rgba(0,0,0,0.1)" 
          >
            <Button
              width="100%"
              colorScheme="blue"
              onClick={(e) => {
                e.stopPropagation();
                navigate("/notifications");
              }}
            >
              View All
            </Button>
          </Box>
        </MenuList>
      )}
    </Menu>
  );
};

export default NotificationIcon;
