import { Navigate, Outlet, useLocation } from 'react-router-dom';
import Header from '../components/header';
import Footer from '../components/footer';
import { useLayoutEffect } from 'react';
import { Container, useMediaQuery } from '@chakra-ui/react';
import ActionButtons from '../components/action-buttons';
import BottomNavigation from '../components/bottom-navigation';
import NowBought from '../components/getNowBought';
import { useScrollRestoration } from '../hooks/useScrollRestoration';
import { useSelector } from 'react-redux';
import InactivityLogoutTimer from '../components/inactivity-logout-timer';
const PublicRoutes = () => {
  useScrollRestoration();
  const location = useLocation();
  let auth = { token: localStorage.getItem('token') };
  const [isSmallerThan767] = useMediaQuery('(max-width: 767px)');
  const redirectionPath = useSelector((state) => state.home.redirectionPath);
  const Wrapper = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children;
  };

  const isCartPage = location.pathname === '/product/cart';

  return !auth.token ? (
    <Wrapper>
      <InactivityLogoutTimer />
      <Header />
      <Container
        maxW='100%'
        padding={'0'}
        bg={isCartPage ? '#EDEDED' : 'white'}
        borderBottom={isCartPage ? '4px solid white' : 'none'}
      >
        <Outlet />
      </Container>
      <ActionButtons />
      {isSmallerThan767 ? <BottomNavigation /> : <Footer />}

      <NowBought />
    </Wrapper>
  ) : (
    <Navigate to={redirectionPath ? redirectionPath : '/'} />
  );
};

export default PublicRoutes;
