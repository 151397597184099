import { createSlice } from '@reduxjs/toolkit';
import {
  login,
  signup,
  sendingOtp,
  resetingPassword,
  verifyingOtp,
  logout,
} from '../services/auth-service';

const initialState = {
  isLoading: false,
  userDetails: null,
  isLogin: true,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setUserDetails(state, action) {
      state.userDetails = action.payload;
    },
  },
});

export const { setIsLoading, setUserDetails } = authSlice.actions;

export default authSlice.reducer;

export const signupHandler = (payload) => async (dispatch) => {
  try {
    return await signup(payload);
  } catch (error) {
    return error;
  }
};
export const loginHandler = (payload) => async (dispatch) => {
  try {
    return await login(payload);
  } catch (error) {
    return error;
  }
};
export const logoutHandler = () => async (dispatch, getState) => {
  try {
    const userData = getState().user.userData;
    return await logout(userData?.id);
  } catch (error) {
    return error;
  }
};
export const sendOtp = (payload) => async (dispatch) => {
  try {
    return await sendingOtp(payload);
  } catch (error) {
    return error;
  }
};
export const verifyOtp = (payload) => async (dispatch) => {
  try {
    return await verifyingOtp(payload);
  } catch (error) {
    return error;
  }
};
export const resetPassword = (payload) => async (dispatch) => {
  try {
    return await resetingPassword(payload);
  } catch (error) {
    return error;
  }
};
