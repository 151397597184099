import React from 'react';
import { Flex, Image } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import home from '../../assets/img/icons/home.svg';
import homeRed from '../../assets/img/icons/homeRed.svg';
import heart from '../../assets/img/icons/heart.svg';
import heartRed from '../../assets/img/icons/ heartRed.svg';
import filter from '../../assets/img/icons/filter.svg';
import filterRed from '../../assets/img/icons/filterRed.svg';
import cart from '../../assets/img/icons/cart.svg';
import cartRed from '../../assets/img/icons/cartRed.svg';
import user from '../../assets/img/icons/user.svg';
import userRed from '../../assets/img/icons/userRed.svg';
import { useDispatch, useSelector } from 'react-redux';
import { setIsCategoryOpen } from '../../redux/homeSlice';

const BottomNavigation = ({ index, setIndex }) => {
  const dispatch = useDispatch();
  
  const { isCategoryOpen } = useSelector((state) => state.home);
  const iconSize = '24px';

  return (
    <Flex
      bg='#FFFFFF'
      pos={'fixed'}
      bottom={'-1px'}
      zIndex={100}
      boxShadow={'0px -1px 1px 0px #00000029'}
      padding={'14px 24px'}
      right={0}
      left={0}
      width={'100%'}
      justify={'space-between'}
      className='bottom-navigation'
    >
      <NavLink to='/'>
        {({ isActive }) => (
          <Flex
            direction='column'
            align='center'
            className={
              isCategoryOpen ? 'noSelect' : isActive ? 'selected' : 'noSelect'
            }
          >
            <Image
              src={isCategoryOpen ? home : isActive ? homeRed : home}
              alt='Home'
              boxSize={iconSize}
              cursor='pointer'
            />
          </Flex>
        )}
      </NavLink>

      <NavLink to='/wishlist'>
        {({ isActive }) => (
          <Flex
            direction='column'
            align='center'
            className={
              isCategoryOpen ? 'noSelect' : isActive ? 'selected' : 'noSelect'
            }
          >
            <Image
              src={isCategoryOpen ? heart : isActive ? heartRed : heart}
              alt='Wishlist'
              boxSize={iconSize}
              cursor='pointer'
            />
          </Flex>
        )}
      </NavLink>

      <Flex
        align={'center'}
        justify={'center'}
        onClick={() => {
          dispatch(setIsCategoryOpen(!isCategoryOpen));
        }}
        className={isCategoryOpen ? 'selected' : 'noSelect'}
      >
        <Image
          cursor={'pointer'}
          src={isCategoryOpen ? filterRed : filter}
          alt='Filter'
          boxSize={iconSize}
        />
      </Flex>

      <NavLink to='/product/cart'>
        {({ isActive }) => (
          <Flex
            direction='column'
            align='center'
            className={
              isCategoryOpen ? 'noSelect' : isActive ? 'selected' : 'noSelect'
            }
          >
            <Image
              src={isCategoryOpen ? cart : isActive ? cartRed : cart}
              alt='Cart'
              boxSize={iconSize}
              cursor='pointer'
            />
          </Flex>
        )}
      </NavLink>

      <NavLink to='/account'>
        {({ isActive }) => (
          <Flex
            direction='column'
            align='center'
            className={
              isCategoryOpen ? 'noSelect' : isActive ? 'selected' : 'noSelect'
            }
          >
            <Image
              src={isCategoryOpen ? user : isActive ? userRed : user}
              alt='Account'
              boxSize={iconSize}
              cursor='pointer'
            />
          </Flex>
        )}
      </NavLink>
    </Flex>
  );
};

export default BottomNavigation;
