import { Flex, Input, Text, Textarea } from '@chakra-ui/react';
import { useField, Field } from 'formik';

const FormInputGroup = ({
  label,
  labelProps,
  as,
  groupValue,
  flexWidth,
  inputProps,

  ...props
}) => {
  const [field, meta] = useField(props);

  return (
    <Flex flexDir='column' gap='10px' width={flexWidth}>
      <Flex gap='10px' align='center'>
        <Text
          as='label'
          htmlFor={props.id || props.name}
          textStyle='body-rg'
          fontSize='0.8rem'
          fontWeight={600}
          lineHeight='18px'
          {...labelProps}
        >
          {label}
        </Text>
      </Flex>
      <Flex gap={'5px'} align={'center'}>
        <Input width={'25%'} value={groupValue}
          fontSize='0.8rem'
          minWidth="50px"
          textAlign="center"
          padding="0 8px" />
        <Field
          as={as === 'Textarea' ? Textarea : Input}
          isInvalid={meta.touched && meta.error}
          borderColor='#000'
          focusBorderColor='darkgray'
          {...field}
          {...props}
        />
      </Flex>

      {meta.touched && meta.error ? (
        <Text textStyle='body-rg' color='red' fontWeight={400}>
          {meta.error}
        </Text>
      ) : null}
    </Flex>
  );
};

export default FormInputGroup;
