import { Box, Divider, Flex, Text } from '@chakra-ui/react';
import React from 'react';
import styles from './recommendations.module.scss';
import RecommendationCategory from '../../recommendation-listing/recommendationsCategory';

const CategoryRecommendations = ({ title, onCartOpen }) => {

  return (
    <Box
      className={styles.recommendations}
      bg='#FFF'
      mt='40px'
    >
      <Flex justify={'space-between'} align={'center'}>
        <Text color={'#000'} fontSize={'16px'} fontWeight={500} mb='15px'>
          {title}
        </Text>
      </Flex>
      <Divider mb='35px' mt='-20px' />

      <RecommendationCategory fromHome onCartOpen={onCartOpen} />
    </Box>
  );
};

export default CategoryRecommendations;
